import React, { Component, createRef } from 'react';
import './landing.less';

import { take } from 'lodash';
import bem from 'helpers/bem';
import { screen } from 'helpers';

import {
  Layout,
  Button,
  Carousel,
  Container,
  Section,
  AppFooter,
  Footer,
  IconPhone,
  Plan,
} from 'components';

import AppFeatureCarousel from 'screens/Landing/AppFeaturesCarousel';

import Hero from './Hero';
// import experienceSummary from 'assets/experience-summary.png';
import handSquare from 'assets/hand-square.png';

import NueLink from './NueLink';

import hand from 'assets/hand.png';

import { Step } from '../sections/Step';
import { Review } from '../sections/Review';
import { Feature } from '../sections/Feature';

import reviews from 'data/reviews.json';

import miamiHerald from 'assets/companies/miami.svg';
import techCrunch from 'assets/companies/techcrunch.svg';
import axios from 'assets/companies/axios.svg';
import wellAndGood from 'assets/companies/wellandgood.svg';
import magazine300 from 'assets/companies/300-magazine.svg';
import inc from 'assets/companies/inc.svg';
import fierceHealth from 'assets/companies/fierce-healthcare.svg';
import southflorida from 'assets/companies/southflorida.svg';

import hero from '../assets/network_hero.png';

import appPerson from 'assets/app-person.png';
import nature from 'assets/nature-tiny.jpg';
import dustin from 'assets/dustin.jpg';
import joland from 'assets/joland.jpg';

import NetworkBanner from '../../Landing/NetworkBanner';

import { GlobalContext } from 'context';

const planColors = ['calmblue', 'nueblue'];

const CallButton = ({ title }) => (
  <Button className="phone" secondary long as={NueLink} to={'/talk-to-us'}>
    <IconPhone /> {title}
  </Button>
);

export function scrollToTargetAdjusted(element) {
  const headerOffset = 90;
  const elementPosition = element.getBoundingClientRect().top;
  const offsetPosition =
    elementPosition - headerOffset - document.body.getBoundingClientRect().top;

  window.scrollTo({
    top: offsetPosition,
    behavior: 'smooth',
  });
}

@bem
@screen
class Page extends Component {
  static contextType = GlobalContext;
  static title = 'It’s your right to feel good.';

  state = {
    stickyHeader: false,
    plans: [],
    reviews: take(reviews, 3),
  };

  plansRef = createRef();

  componentDidMount() {
    window.addEventListener('scroll', this.listenToScroll, { passive: true });
    window?.gtag('event', 'view_landing');
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.listenToScroll);
  }

  listenToScroll = () => {
    const position = window.pageYOffset;
    const isMobile = window.innerWidth < 1024;

    this.setState({
      stickyHeader: isMobile ? position > 30 : position > 100,
    });
  };

  loadMoreReviews = () => {
    if (this.state.reviews.length === reviews.length) {
      this.setState({
        reviews: take(reviews, 3),
      });
      return;
    }
    this.setState({
      reviews: take(reviews, this.state.reviews.length + 3),
    });
  };

  render() {
    const { plans } = this.context;

    return (
      <div className="discover">
        {this.props.banner ? <NetworkBanner button={true} style={{position: "absolute"}}/> : <></>}

        {this.props.navBar}

        <div className="welcome ">
          <Container>
            <div className="inner">
              <Hero scrollToRef={this.props.sectionRef} />
              <div className="column image">
                <img src={this.props.secondaryHero || hero} />
              </div>
            </div>
          </Container>
        </div>
        <Section eggshell style={{ marginTop: '-50px', marginBottom: '-50px' }}>
          {this.props.children}
        </Section>
        <Section sleep>
          <Container style={{ paddingTop: '2em' }}>
            {this.props.videoSection}
          </Container>
          <div className="landing">
            <div className="our-process" style={{ marginBottom: '1em' }}>
              <Container>
                <h3 className="title">Our Process</h3>
                <Layout stackable horizontal>
                  <div className="progress-item">
                    <h1 className="h1">discover</h1>
                    <p className="bodytext">
                      <img
                        src={nature}
                        width="37px"
                        style={{
                          float: 'left',
                          marginRight: '10px',
                          borderRadius: '3px',
                        }}
                      />
                      Select one of our two treatment programs, Nue Reset and
                      Nue You. Can’t decide? Our Welcome Team will help you
                      select a journey that meets your needs.
                    </p>
                  </div>
                  <div className="progress-item ">
                    <h1 className="h1">grow</h1>
                    <p className="bodytext">
                      <img
                        src={joland}
                        height="50px"
                        style={{
                          float: 'left',
                          marginRight: '10px',
                          borderRadius: '3px',
                        }}
                      />
                      Embark on your personalized healing journey. As you move
                      through your prescribed doses, you’ll share insights and
                      feedback via the app with your clinician, ensuring that
                      your experience is safe and productive.
                    </p>
                  </div>
                  <div className="progress-item">
                    <h1 className="h1">expand</h1>
                    <p className="bodytext">
                      <img
                        src={dustin}
                        height="50px"
                        style={{
                          float: 'left',
                          marginRight: '10px',
                          borderRadius: '3px',
                        }}
                      />
                      Solidify your treatment insights into lasting, positive
                      change. Attend virtual integration groups and health
                      coaching sessions to turn your treatment insights into
                      healthy new behaviors.
                    </p>
                  </div>
                </Layout>
                <div className="bottom">
                  <div className="image">
                    <img height={'400px'} src={appPerson} />
                  </div>
                  <div className="content">
                    <h3 className="h1" style={{ marginBottom: '20px' }}>
                      Book a free discovery call with our Welcome Team.{' '}
                    </h3>
                    <CallButton long title={'Talk with us'} />
                  </div>
                </div>
                <div className="extra-info">
                  <p>
                    Nue Life Health, PBC, is a next-generation mental wellness
                    company that believes in the power of psychedelics to
                    catalyze lasting change. Through evidence-based therapies,
                    psychedelic integration, health coaching, and AI, we are
                    able to deliver accessible at-home mental health treatment
                    programs that address the root cause of patients’ trauma and
                    give them the tools to create lasting change.
                  </p>
                  <p>
                    To learn more about our approach to treatment and our
                    commitment to ethical ketamine therapy, please read our{' '}
                    <NueLink to={`/impact`}>Impact Statement</NueLink>.
                  </p>
                </div>
              </Container>
            </div>
          </div>

          <div className="reviews">
            <Container>
              <h3
                style={{ margin: 'auto', textAlign: 'center' }}
                className="title">
                Our team has delivered 35,500+ treatments for depression,
                anxiety, PTSD & other conditions.
              </h3>
            </Container>

            <Container>
              <div className="reviews__list">
                {this.state.reviews.map((review, index) => (
                  <Review review={review} key={index} />
                ))}
              </div>
            </Container>
          </div>
          <div className="steps">
            <Container>
              <h2 className="title">Why Nue Life?</h2>
            </Container>

            <div className="container">
              <div className="column">
                <Step color="prosperity" title="Effective">
                  Oral ketamine provides a rapid antidepressant effect within
                  hours of the first dose - unlike SSRIs, which often take weeks
                  to work. The psychedelic component of treatment offers
                  insights and understanding that empower you to address the
                  root cause of your suffering, not just the symptoms.
                </Step>
                <Step color="love" dark title="Safe">
                  Ketamine has full FDA approval and is used in hospitals every
                  day as an anesthetic. Multiple clinical trials have shown
                  ketamine’s profound ability to treat depression, anxiety,
                  bipolar illness, and PTSD. It’s shorter-acting and more
                  predictable than other psychedelics.
                </Step>
              </div>
              <div className="column offset">
                <Step color="nueblue" title="Affordable">
                  We developed our in-home programs to replace expensive IV
                  infusions. Our companion app provides a cost-effective way for
                  you to interact with our clinical team and learn how to
                  optimize your treatment.
                </Step>

                <Step color="changeyellow" title="Ongoing">
                  Healing doesn’t happen in a vacuum - we believe that people
                  heal people. Our programs include participation in
                  clinician-led integration groups and health coaching sessions
                  for additional support.
                </Step>
              </div>
            </div>
          </div>

          <div
            className="testimonials"
            style={{ margin: 0, top: 0, background: 'none' }}>
            <Container>
              <Layout horizontal bottom stackable>
                <div className="testimonials__item">
                  <a
                    rel="noreferrer"
                    href="https://www.axios.com/nue-life-health-telemedicine-psychedelic-treatment-ab143443-88e0-4342-9fd1-434c436bfd55.html"
                    target="_blank">
                    <img style={{ height: '34px' }} src={axios} />
                  </a>
                </div>
                <div className="testimonials__item">
                  <a
                    href="https://techcrunch.com/2021/06/03/nue-life-health-raises-3-3m-for-its-psychedelics-meets-tech-mental-wellness-platform/?guccounter=1&guce_referrer=aHR0cHM6Ly93d3cubnVlLmxpZmUv&guce_referrer_sig=AQAAAH7D0hR_Fyn0jD7xa4lo_ePjQKXhIV08utFDv3_4ZU0iQBTAJJnnNIQVnLVaFo9ciFu3hgBRc93YGiK88efOXMRoYLDIA5OQyX7NnwKaSnm7iub-fEGaMp6zi1VsoRZm3rRIccF5zgHTUjprDbDhyyTI5l15bZ1OMAZVts5q97Lx"
                    rel="noreferrer"
                    target="_blank">
                    <img src={techCrunch} />
                  </a>
                </div>

                <div className="testimonials__item">
                  <a
                    rel="noreferrer"
                    href="https://www.miamiherald.com/news/business/article255620521.html"
                    target="_blank">
                    <img src={miamiHerald} />
                  </a>
                </div>
                <div className="testimonials__item">
                  <a
                    rel="noreferrer"
                    href="https://www.bizjournals.com/southflorida/news/2021/11/08/psychedelics-meet-tech-with-nue-life-s-new-at-home.html"
                    target="_blank">
                    <img style={{ height: '43px' }} src={southflorida} />
                  </a>
                </div>
              </Layout>
              <br className="only-desktop" />
              <br className="only-desktop" />
              <Layout horizontal bottom stackable spread>
                <div className="testimonials__item">
                  <a
                    rel="noreferrer"
                    href="https://www.inc.com/melissa-angell/ketamine-therapy-artificial-intelligence-nue-life-antidepressants.html"
                    target="_blank">
                    <img
                      style={{ height: '50px', marginBottom: '4px' }}
                      src={inc}
                    />
                  </a>
                </div>
                <div className="testimonials__item">
                  <a
                    rel="noreferrer"
                    href="https://www.wellandgood.com/fitness-wellness-trends/health/psychedelics-mental-health"
                    target="_blank">
                    <img
                      style={{ height: '30px', marginBottom: '8px' }}
                      src={wellAndGood}
                    />
                  </a>
                </div>
                <div className="testimonials__item">
                  <a
                    rel="noreferrer"
                    href="https://www.fiercehealthcare.com/digital-health/new-telehealth-startup-treating-mental-illness-ketamine-therapy"
                    target="_blank">
                    <img style={{ height: '60px' }} src={fierceHealth} />
                  </a>
                </div>
                <div className="testimonials__item">
                  <a
                    rel="noreferrer"
                    href="https://303magazine.com/2022/01/mental-health-care/"
                    target="_blank">
                    <img
                      style={{ height: '34px', marginBottom: '6px' }}
                      src={magazine300}
                    />
                  </a>
                </div>
              </Layout>
            </Container>
          </div>

          <div className="plans" id="plans">
            <Container style={{ maxWidth: '1400px' }}>
              <h2 className="title" ref={this.plansRef}>
                Find the right program & <br /> start owning your wellness today
              </h2>

              {plans.length > 0 && (
                <div className="wrapper">
                  {plans.map((plan, index) => (
                    <Plan
                      LinkComponent={NueLink}
                      key={plan.id}
                      plan={plan}
                      color={planColors[index]}
                    />
                  ))}
                </div>
              )}
            </Container>

            <Container style={{ textAlign: 'center' }}>
              <div className="options bodytext">
                Ask our welcome team about guaranteed financing options.
              </div>
              <div className="bodytext sidenote">
                *All prescriptions subject to monthly medical evaluation of
                individual risks and benefits. Additional pharmacy fees may
                apply beyond initial month.
              </div>
              <div className="bodytext sidenote">
                **Your clinician may recommend additional lab testing.
                Additional lab testing fees apply, unless specifically included
                in the tier.
              </div>
            </Container>
          </div>
        </Section>

        <div className="not-alone">
          <Container>
            <div className="header">
              <h3 className="title" style={{ paddingBottom: '1em' }}>
                Have you made up your mind to <br />
                <span className="italic">change your mind?</span>
                <br />
                <br />
                <CallButton title={'Get Started'} />
              </h3>
            </div>
          </Container>

          <div className="better-together">
            <Container>
              <Layout horizontal stackable>
                <div>
                  <h1 className="title" style={{ textAlign: 'center' }}>
                    Better together
                  </h1>

                  <p
                    className="bodytext"
                    style={{
                      maxWidth: '600px',
                      margin: 'auto',
                      paddingBottom: '3em',
                    }}>
                    <img
                      height="160px"
                      src={hand}
                      style={{ float: 'right', paddingLeft: '20px' }}
                    />
                    While there are other at-home <strong>psychedelic therapy</strong> options,
                    our programs are the only ones that include integration
                    groups and health coaching as core treatment components.
                  </p>
                </div>
              </Layout>
            </Container>
          </div>

          <Container>
            <Layout horizontal stackable>
              <div className="plans__image" style={{ textAlign: 'center' }}>
                <img
                  style={{ marginTop: '4em' }}
                  src={this.props.handSquare || handSquare}
                />
              </div>
              <div
                className="plans__content left-space"
                style={{ marginTop: '4em' }}>
                <h4 className="title">An app like no other</h4>
                <p className="bodytext">
                  With musicologist-designed playlists, voice journals, and mood
                  tracking, we’re bringing a seamless experience to at-home
                  psychedelic therapy.
                </p>
                <h4 className="title">Science first</h4>
                <p className="bodytext">
                  Ketamine restores these synaptic connections damaged by stress
                  by targeting the NMDA receptor and releasing a surge of
                  glutamate, which helps grow new synapses.
                </p>
              </div>
            </Layout>
          </Container>
        </div>

        <AppFeatureCarousel />

        <AppFooter />
        <Footer LinkComponent={NueLink} />
      </div>
    );
  }
}

export { Page };
